/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttributeProduct } from './AttributeProduct';
import type { Author } from './Author';
import type { Category } from './Category';
import type { Image } from './Image';
import type { ProductDescription } from './ProductDescription';
import type { PromotionProduct } from './PromotionProduct';
import type { Publisher } from './Publisher';
import type { Serie } from './Serie';
import type { Tag } from './Tag';
import type { TagProduct } from './TagProduct';

export type Product = {
    productId: number;
    /**
     * EAN
     */
    ean: string | null;
    /**
     * $name
     */
    name: string;
    /**
     * available
     */
    available: number;
    /**
     * Was this product available in last 30 days
     */
    availableInLast30Days: boolean;
    /**
     * Cena detaliczna
     */
    priceRetail?: number | null;
    /**
     * Product availability in warehouse
     */
    productAvailableStatus: Product.productAvailableStatus | null;
    /**
     * Is product a package
     */
    isPackage?: boolean;
    categoryId?: number | null;
    descriptionId?: number | null;
    /**
     * Product url
     */
    path?: string;
    /**
     * Amount
     */
    amount?: number | null;
    /**
     * Is product an outlet
     */
    isOutlet?: boolean;
    parentCategories?: Array<Category>;
    discounted?: boolean;
    description?: ProductDescription | null;
    book: boolean;
    schoolTextBook?: boolean;
    game: boolean;
    /**
     * Is product a presale before premiere
     */
    presale?: boolean;
    categoriesIds?: Array<number>;
    availableAt?: number;
    toy?: boolean;
    menApprovalNumber?: string | null;
    imageUrl?: string | null;
    price?: number | null;
    category?: Category | null;
    /**
     * $authors
     */
    authors: Array<Author>;
    minimum30daysPrice?: number | null;
    image: Image;
    images: Array<Image>;
    averageRating?: number | null;
    availableFrom: string | null;
    /**
     * Publisher> $publishers
     */
    publishers: Record<string, Publisher>;
    /**
     * $attributes
     */
    attributes?: Array<AttributeProduct>;
    /**
     * $tagProducts
     */
    tagProducts?: Array<TagProduct>;
    /**
     * $tags
     */
    tags?: Array<Tag>;
    /**
     * $series
     */
    series?: Array<Serie> | null;
    /**
     * $productPromotions
     */
    productPromotions?: Array<PromotionProduct>;
    reviewsCount?: number;
    /**
     * Attribute set ID
     */
    attributeSetId?: number | null;
};

export namespace Product {

    /**
     * Product availability in warehouse
     */
    export enum productAvailableStatus {
        UNAVAILABLE = 'UNAVAILABLE',
        STORE = 'STORE',
        OTHER = 'OTHER',
        PREDICTION = 'PREDICTION',
        REALIZATION = 'REALIZATION',
    }


}

