export type LandingPageElementClickEvent = {
	productName?: string|undefined,
	productId?: number|undefined,
	imageUrl?: string|undefined,
	section: string,
	element: string,
};

export enum LandingPageEventNames {
	Cta = 'cta',
}
